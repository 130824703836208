import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Stack from "../../components/stack"
import Box from "../../components/box"
import Heading from "../../components/heading"
import Paragraph from "../../components/paragraph"

const Impressum = () => {
  const data = useStaticQuery(graphql`
    query {
        beigelegterBrief: file(relativePath: { eq: "og_images/beig-brief.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 500, height: 651, layout: FIXED)
          }
        }
    }
  `)
  return (
    <Stack>
      <Box bg="muted" p={[6, 12]} height="100%">
            <Stack space={6}>
              <Heading as="h3" level={4}>
                Impressum
              </Heading>
              <Paragraph>
                Turm in Flammen! Die Fahndungsakte »Feuerteufel«<br />
                20. September 2024 bis 9. Januar 2025<br />
                Hölderlinturm Tübingen
              </Paragraph>
              <Paragraph>
                Gefördert aus Landesmitteln durch die Arbeitsstelle für literarische Museen, 
                Archive und Gedenkstätten in Baden-Württemberg (Deutsches Literaturarchiv Marbach)<br /><br />
                In Kooperation mit dem Landesarchiv Baden-Württemberg, Abteilung Staatsarchiv Sigmaringen 
                und dem Zentrum für Medienkompetenz der Universität Tübingen
              </Paragraph>
              <Paragraph>
                <strong>Kuratierung:</strong>                   Florian Mittelhammer<br />
                <strong>Illustrationen und Cartoons:</strong>   Peter Puck<br />
                <strong>Szenografie:</strong>                   Stephan Potengowski<br />
                <strong>Grafik:</strong>                        Martin Bertele<br />
                <strong>Vertonung:</strong>                     Stefanie Schleicher<br />
                <strong>Sprecherinnen und Sprecher:</strong>    Ulrich Hägele, Gilbert Mieroph, Nils Mano, David Mayer, Julia Zschauer, Jonathan Wiese, Janis Hanenberg, Lieselotte Kohl, Stefanie Schleicher
              </Paragraph>
            </Stack>
        </Box>
    </Stack>
  )
}

export default Impressum
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../components/container"
import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import Constrain from "../../components/constrain"
import Grid from "../../components/grid"
import Box from "../../components/box"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Heading from "../../components/heading"
import Text from "../../components/text"
import AudioPlayer from "../../components/audio-player"
import ImageHotspot from "../../components/image-hotspot"
import ImageHotspots from "../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../components/image-hotspots-modal-template"

const Verdaechtigungen = () => {
  const data = useStaticQuery(graphql`
    query {
      verdBetz: file(
        relativePath: { eq: "og_images/verd-betz.jpg" }
      ) {
        ...largeImage
      }
      verdEberhardt: file(
        relativePath: { eq: "og_images/verd-eberhardt.jpg" }
      ) {
        ...largeImage
      }
      verdFeuerteufel: file(
        relativePath: { eq: "og_images/verd-feuerteufel.jpg" }
      ) {
        ...largeImage
      }
      verdGassenmeier: file(
        relativePath: { eq: "og_images/verd-gassenmeier.jpg" }
      ) {
        ...largeImage
      }
      verdHaller: file(
        relativePath: { eq: "og_images/verd-haller.jpg" }
      ) {
        ...largeImage
      }
      verdSeelos: file(
        relativePath: { eq: "og_images/verd-seelos.jpg" }
      ) {
        ...largeImage
      }
      turmHeute: file(
        relativePath: { eq: "og_images/turm-heute.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Title top="Verdächtigungen" middle="" bottom="" />
        <Stack space={6}>
            <Intro>
                Dass es Brandstiftung gewesen sein muss, scheint durch die Vernehmung erwiesen. Aber wer kann es gewesen sein?
            </Intro>
            <Paragraph>
                Eine weitere heiße Spur liefert Schriftsteller Bruno Wille (1860-1928). Als Jugendlicher erlebte er den Brand mit, 
                später erlangte er unter anderem als Gründer der Berliner Volksbühne Berühmtheit. 
                In seinem autofiktionalen Roman »Der Glasberg« erinnerte er sich an seine Jugendjahre in Tübingen zurück. 
                Darin beschreibt er akkurat die Vorkommnisse der Brandnacht, und erzählt, wenn auch in etwas abgewandelter Form, 
                vom Streit zwischen Eberhardt (im Roman Spengler) und Betz. Auch die im Roman erwähnte Brandserie, 
                die nach 1874 ganz Tübingen in Angst und Schrecken versetzte, ist historisch verbürgt. 
                An anderer Stelle wiederum scheint manches hinzugedichtet worden zu sein. Unklar bleibt: 
                Sind der nie gefasste Feuerteufel von Tübingen, der damals für die Brandserie verantwortlich gemacht wurde, 
                und der Brandstifter des Eberhardtschen Hauses ein und dieselbe Person, wie der Roman nahelegt?
            </Paragraph>
            <AudioPlayer
                src={`../../audio/sample.mp3`}
                title={
                    <Text as="span" sans>
                        Weitere Vermutungen{" "}
                        <Text bold as="strong"></Text>
                    </Text>
                }
            />
            <Paragraph>
                Welche dieser Personen war es? So richtig werden wir den Fall vermutlich eher nicht beantworten können. 
                Aber vielleicht hast Du ja eine Idee, wer es gewesen sein kann?
            </Paragraph>
            <Grid columns={[2, 3]} space={6}>
                <ImageHotspots
                    image={
                        <Image
                            alt="Schwarzweiß-Karikatur des Verdächtigen Eberhardt"
                            image={data.verdEberhardt.childImageSharp.gatsbyImageData}
                        />
                    }
                >
                    <ImageHotspot x={44.5} y={92.6} name="eberhardt">
                        <ImageHotspotsModalTemplate title="Carl Friedrich Eberhardt">
                            <Paragraph>
                                Biographisches/Vorstrafen: 
                                <BulletList>
                                    <Bullet>geb. 1827, Schuhmacher, Badbesitzer</Bullet>
                                    <Bullet>erwarb das Haus 1868, eröffnete 1874 den Warm- und Flussbadebetrieb; keine Vorstrafen</Bullet>
                                </BulletList>
                                <br />Mögliches Motiv: 
                                <BulletList>
                                    <Bullet>Versicherungsbetrug; er erhielt eine nicht unbeträchtliche Summe (10.043,50 Mark; das ist mehr als das 20-fache des durchschnittlichen Jahresbruttoeinkommens eines Arbeiters in dieser Zeit, und mehr als das 3-fache seines eigenen Vermögens). Die Finanzierung des Wiederaufbaus war damit gesichert. Das Haus konnte er nun endlich ganz nach eigenen Vorstellungen erweitern und bot viel mehr Platz für den Badebetrieb.</Bullet>
                                </BulletList>
                                <br />Entlastend: 
                                <BulletList>
                                    <Bullet>Hohes Risiko für Leib und Leben; er und seine Familie mussten über Leitern in Sicherheit gebracht werden.</Bullet>
                                </BulletList>
                            </Paragraph>
                        </ImageHotspotsModalTemplate>
                    </ImageHotspot>
                </ImageHotspots>    

                <ImageHotspots
                    image={
                        <Image
                            alt="Schwarzweiß-Karikatur des Verdächtigen Brauwirt Betz"
                            image={data.verdBetz.childImageSharp.gatsbyImageData}
                        />
                    }
                >
                    <ImageHotspot x={44.6} y={92.6} name="betz">
                        <ImageHotspotsModalTemplate title="Johann Jakob Bez">
                            <Paragraph>
                                Biographisches/Vorstrafen: 
                                <BulletList>
                                    <Bullet>geb. 1837, Bierbrauer</Bullet>
                                    <Bullet>übernahm durch Einheirat die Brauerei in der Bursagasse 4 und betreibt seit 1862 dort eine Schankwirtschaft (heute Restaurant Hölderlinturm)</Bullet>
                                    <Bullet>mehrfach vorbestraft; u.a. wegen unkontrolliertem Weinausschank, Ungehorsam, Ehrenkränkung, Beleidigung, Hundesteuergefährdung und »Verunreinigung und Versperrung des öffentl. Wandels und der Straße vor u. bei seinem Hause«</Bullet>
                                    <Bullet>zapfte 1874 während Wasserknappheit unerlaubt die Lützelquelle ab. Er führte dafür in seinem Keller in der Gartenstraße geheime Sprengungen durch, verlegte Bleirohre und ließ das Wasser in Fässern zur Brauerei karren</Bullet>
                                </BulletList>
                                <br />Alibi: 
                                <BulletList>
                                    <Bullet>nicht stichhaltig; die Brauereigesellen und die Dienstmägde sind vor der Tatzeit alle schon zu Bett gegangen; gemeinsam mit seiner Frau arbeitete er zwar in der Wirtschaft, ging dabei aber immer wieder auch hinaus. Es wäre ein leichtes gewesen, den zuvor präparierten</Bullet>
                                </BulletList>
                                <br />Mögliches Motiv:
                                <BulletList>
                                    <Bullet>Anhaltende Nachbarschaftsstreitigkeiten mit Carl Friedrich Eberhardt; wird gestützt durch Aussagen von Herrn und Frau Eberhardt. Zwei Jahre nach dem Brand klagte Bez Eberhardt allerdings wegen Beleidigung in Bezug auf die Brandstiftung an.</Bullet>
                                </BulletList>
                                <br />Entlastend:
                                <BulletList>
                                    <Bullet>Schäden auch an der Brauerei durch das Feuer</Bullet>
                                    <Bullet>Hohes Risiko für sein eigenes Eigentum, eigene Familie wurde nicht vorgewarnt</Bullet>
                                </BulletList>
                            </Paragraph>
                        </ImageHotspotsModalTemplate>
                    </ImageHotspot>
                </ImageHotspots>   

                <ImageHotspots
                    image={
                        <Image
                            alt="Schwarzweiß-Karikatur des Verdächtigen Feuerteufel"
                            image={data.verdFeuerteufel.childImageSharp.gatsbyImageData}
                        />
                    }
                >
                    <ImageHotspot x={44.5} y={92.6} name="feuerteufel">
                        <ImageHotspotsModalTemplate title="Der Feuerteufel von Tübingen, Identität unbekannt">
                            <Paragraph>
                                Biographisches/Vorstrafen: 
                                <BulletList>
                                    <Bullet>Identität unbekannt; Vermutlich junger, männlicher Täter; es gab Sichtungen; eine Täterbeschreibung ist nicht vorhanden</Bullet>
                                </BulletList>
                                <br />Mögliches Motiv:
                                <BulletList>
                                    <Bullet>Pyromane</Bullet>
                                </BulletList>
                                <br />Hintergrund:
                                <BulletList>
                                    <Bullet>
                                        1874–1878 suchte eine Serie von über 30 Bränden die Stadt heim, nur bei wenigen war die Ursache Unachtsamkeit. 
                                        Die Ähnlichkeit und schiere Zahl der Brände war frappierend! Oberamtmann Sandberger nahm Ermittlungen auf 
                                        und richtete Bürgerwehren als Nachtwachen ein. Die Verdächtigungen stellten sich allesamt als haltlos heraus. 
                                        Im Februar 1877 kamen zwei Feuerwehrmänner ums Leben, Sandberger konnte aber immer noch keinen Tatverdächtigen verhaften. 
                                        Es schaltete sich das Innenministerium in Stuttgart ein, erstattete dem König Bericht 
                                        über »die im höchsten Grade auffallende und beängstigende unmittelbare Aufeinanderfolge der Brandfälle«, 
                                        und schickte als Sonderermittler den »hierzu besonders befähigten hiesigen Polizei-Inspektor Kern« nach Tübingen. 
                                        Er sollte die Fahndung nach dem Feuerteufel vorantreiben. Erfolg hatte er keinen, der Täter wurde nicht gefasst.
                                        Die Zahl der Brände ebbte im Laufe des Jahres 1878 von alleine ab.
                                    </Bullet>
                                </BulletList>
                                <br />Dagegen spricht:
                                <BulletList>
                                    <Bullet>Das Haus Eberhardts passt nicht ins Muster. Die Brände gingen oft von unbewohnten Wirtschaftsgebäuden (Scheunen, Ställen) aus. </Bullet>
                                </BulletList>
                                <br />Weiterhin ungeklärt:
                                <BulletList>
                                    <Bullet>Einzeltäter, mehrere unabhängig agierende Täter und Trittbrettfahrer, oder zusammenhängende »Mordbrennerbande«? Wusste Bruno Wille mehr?</Bullet>
                                </BulletList>
                                <br />Auffällig:
                                <BulletList>
                                    <Bullet>An mindestens zwei Tatorten wurden die verkohlten Überreste fremdsprachiger Zeitungen gefunden!</Bullet>
                                </BulletList>
                            </Paragraph>
                        </ImageHotspotsModalTemplate>
                    </ImageHotspot>
                </ImageHotspots>  

                <ImageHotspots
                    image={
                        <Image
                            alt="Schwarzweiß-Karikatur des Verdächtigen Gassenmeier"
                            image={data.verdGassenmeier.childImageSharp.gatsbyImageData}
                        />
                    }
                >
                    <ImageHotspot x={44.5} y={92.6} name="gassenmeier">
                        <ImageHotspotsModalTemplate title="Louis Gassenmeier (?); wahre Identität unbekannt">
                            <Paragraph>
                                Biographisches/Vorstrafen:  
                                <BulletList>
                                    <Bullet>Mitarbeiter in der Bierbrauerei Bez, Diener der Studentenverbindung Corps Rhenania, ehm. Müllergeselle; zum Zeitpunkt der Tat vielleicht 20 Jahre alt</Bullet>
                                    <Bullet>wird beschrieben mit »graue[m], pockennarbigen Gesicht und entzündeten Augen«, trägt manchmal »Sonntagsanzug, Veilchenstrauß im Knopfloch, eine krumme Feder am Hütchen«</Bullet>
                                    <Bullet>Verdacht auf Mord an seiner schwangeren Verlobten; es kam aber nicht zur Anklage</Bullet>
                                    <Bullet>Romanfigur bei Bruno Wille; zum Zeitpunkt der Tat lebte niemand mit dem Namen in Tübingen. Aber vielleicht könnte ja eine reale Person mit anderem Namen für ihn Modell gestanden haben?</Bullet>
                                </BulletList>
                                <br />Mögliches Motiv: 
                                <BulletList>
                                    <Bullet>Pyromane</Bullet>
                                    <Bullet>Rache an Spengler(=Eberhardt), weil er ihn vor der Polizei beschuldigte, dass er seine Braut bewusst im Neckar ertrinken ließ; Versuch, sich beim vormaligen Arbeitgeber, den Rhenanen, und seinem aktuellen Arbeitgeber, dem Bierbrauer Bez, beliebt zu machen.</Bullet>
                                </BulletList>
                                <br />Entlastend:
                                <BulletList>
                                    <Bullet>Tod des Mädchens im Neckar ist nicht belegt. Ist die Person gänzlich fiktiv? Wer verbirgt sich dahinter? Sonst hochgradig verdächtig!</Bullet>
                                </BulletList>
                                <br />Offene Fragen:
                                <BulletList>
                                    <Bullet>Gibt es Verbindungen zum Tübinger Feuerteufel? Kannte Wille den Feuerteufel??</Bullet>
                                </BulletList>
                            </Paragraph>
                        </ImageHotspotsModalTemplate>
                    </ImageHotspot>
                </ImageHotspots>   

                <ImageHotspots
                    image={
                        <Image
                            alt="Schwarzweiß-Karikatur des Verdächtigen Seelos"
                            image={data.verdSeelos.childImageSharp.gatsbyImageData}
                        />
                    }
                >
                    <ImageHotspot x={44.5} y={92.6} name="seelos">
                        <ImageHotspotsModalTemplate title="Friedrich August (?) Seelos">
                            <Paragraph>
                                Biographisches:  
                                <BulletList>
                                    <Bullet>geb. 1853, Uhrmacher</Bullet>
                                    <Bullet>1870 zu drei Monate Haft verurteilt wegen im Affekt verübter Körperverletzung und Ehrenkränkung</Bullet>
                                </BulletList>
                                <br />Mögliches Motiv: 
                                <BulletList>
                                    <Bullet>unbekannt</Bullet>
                                </BulletList>
                                <br />Weiterhin:
                                <BulletList>
                                    <Bullet>Der einzige Hinweis auf die Täterschaft stammt von Kunsthistoriker Prof. Dr. Konrad Lange, aus dem Jahr 1915: »[Das Haus brannte ab] infolge von Brandstiftung durch den Uhrmacher Seelos, wie mir gesagt wurde.«</Bullet>
                                </BulletList>
                                <br />Auffällig:
                                <BulletList>
                                    <Bullet>In direkter Nachbarschaft des Wohnhauses Seelos in der Neckarhalde wütete im Februar 1877 einer der schlimmsten Brände der Feuerteufel-Serie! Dabei wurde auch Haus und Werkstatt der Familie Seelos beschädigt.</Bullet>
                                </BulletList>
                            </Paragraph>
                        </ImageHotspotsModalTemplate>
                    </ImageHotspot>
                </ImageHotspots>   

                <ImageHotspots
                    image={
                        <Image
                            alt="Schwarzweiß-Karikatur des Verdächtigen Feuerwehrkommandant Haller"
                            image={data.verdHaller.childImageSharp.gatsbyImageData}
                        />
                    }
                >
                    <ImageHotspot x={44.5} y={92.6} name="haller">
                        <ImageHotspotsModalTemplate title="Julius Haller">
                            <Paragraph>
                                Biographisches/Vorstrafen:
                                <BulletList>
                                    <Bullet>geb. 1815, Zimmermann, Werkmeister, Badbesitzer, Kommandant der Tübinger Feuerwehr </Bullet>
                                    <Bullet>eröffnete im Juni 1868 die erste kesselbeheizte Warm-Badeanstalt in Tübingen</Bullet>
                                    <Bullet>vorbestraft wegen »Verdacht der Beihilfe zu hochverratherischen Umtrieben« und Abweichung von Bauvorschriften.</Bullet>
                                </BulletList>
                                <br />Mögliches Motiv: 
                                <BulletList>
                                    <Bullet>
                                        Konkurrenzbeseitigung; wird gestützt durch eine mündliche Überlieferung: »Eine mittlerweile verstorbene Anwohnerin erzählte, 
                                        dass sie als Kind oft im Hölderlinturm war und bei der Familie Eberhardt spielen durfte. Sie sprach von Brandstiftung, 
                                        da das neu eingebaute Bad beheizbar war und deshalb als Konkurrenzunternehmen zu den vorhandenen Badeanstalten galt.«
                                    </Bullet>
                                </BulletList>
                                <br />Entlastend:
                                <BulletList>
                                    <Bullet>hochverdienstvoller erster Kommandant der Feuerwehr seit 1847</Bullet>
                                    <Bullet>Eberhardt war mit seinen zwei Badekabinetten keine große Konkurrenz</Bullet>
                                    <Bullet>
                                        Eberhardt beauftragte vermutlich Hallers gleichnamigen Sohn, ebenfalls Werkmeister, 
                                        auch nach dem Brand mit Baumaßnahmen am Haus. Zuvor hat er selbst oder aber sein Sohn Julius Haller Jun. 
                                        bereits den Ausbau 1874 betreut.
                                    </Bullet>
                                </BulletList>
                            </Paragraph>
                            <Paragraph>
                                Offene Fragen:<br />
                                Wer verbirgt sich hinter dem konkurrierenden Badbetreiber? Hallers Bad war zu der Zeit die einzige andere gewerbliche Badeanstalt; 
                                das Neckarbad spielte als Badeanstalt kaum eine Rolle mehr, u. betrieb nur zwei Flussbadehütten, die es auch an anderen Stellen im Neckar gab.
                            </Paragraph>
                        </ImageHotspotsModalTemplate>
                    </ImageHotspot>
                </ImageHotspots>   
            </Grid>
            <Intro>
                <br />Nach dem Brand und der Auszahlung der Versicherungsgelder wurde das Gebäude wiederaufgebaut. 
                Der Erker am Haus wurde als Rundturm im historistischen Stil neu aufgeführt, 
                das neue Spitzdach verstärkt den turmähnlichen Charakter und ist vielleicht 
                an den vormaligen mittelalterlichen Wehrturm angelehnt, auf dessen Fundament er steht.
            </Intro>
            <Image
                image={data.turmHeute.childImageSharp.gatsbyImageData}
                alt="Hochauflösende Farb-Fotografie des Hölderlinturm heute"
            />
            <Intro>
                Im Lageplan zum Wiederaufbau erhält er erstmals die Bezeichnung „Hölderlins Thurm“
            </Intro>
        </Stack>
    </Stack>
  )
}

export default Verdaechtigungen